import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LocalStorageService } from '../../services/storage/local-storage.service';
import { StateService } from '../../services/storage/state.service';
import {
  MatDialog,
  MatDialogRef,
  MatDialogState,
} from '@angular/material/dialog';
import { PanelService } from '../../services/api/panel.service';
import { CountryDetail } from '../../models/profile.mode';
import { StoreRegionService } from '../../services/store-region.service';
import { PreferenceService } from '../../services/preference/preference.service';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from '../../services/device-detector/device-detector.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  @ViewChild('categoriesDialog') categoriesDialog: any;
  isLoggedIn: boolean = false;
  isSearchOpened: boolean;
  isDesktop: boolean;
  hideNavigation: boolean = false;
  hideControllers: boolean = false;
  dialogRef: MatDialogRef<any>;
  categories: any;
  categoryDialogIsOpen: boolean;
  selectedRegion: CountryDetail;
  @ViewChild('prefrencesDialog') prefrencesDialog: any;
  isBrowser: boolean;

  constructor(
    private router: Router,
    private stateService: StateService,
    private localStorageService: LocalStorageService,
    private dialog: MatDialog,
    private panelService: PanelService,
    private storeRegionService: StoreRegionService,
    private preferenceService: PreferenceService,
    private deviceDetectorService: DeviceDetectorService,
    private translateService: TranslateService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.deviceDetectorService
      .$breakpoint()
      .subscribe((sizes) => (this.isDesktop = sizes.matches));
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.translateService.setDefaultLang('en');
    this.translateService.use('en');
    this.selectedRegion = this.storeRegionService.selectedRegion;
    this.checkRoutes();
    this.getCategoryList();

    this.hideNavigation = this.isNoneNavigationRoute;
    this.hideControllers = this.isCryptoRoute;
    this.setLoginStatus();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.hideNavigation = this.isNoneNavigationRoute;
        this.hideControllers = this.isCryptoRoute;
        this.setLoginStatus();
      }
    });
  }

  openCategoeis() {
    this.closeDialog();

    this.dialogRef = this.dialog.open(this.categoriesDialog, {
      autoFocus: false,
      restoreFocus: false,
      position: {
        top: '72px',
        left: '260px',
      },
      panelClass: ['dialog-full-width'],
      backdropClass: ['transparent-dialog-backdrop'],
    });
    this.categoryDialogIsOpen = true;
    this.dialogRef.afterClosed().subscribe((data) => {
      this.categoryDialogIsOpen = false;
    });
  }

  openPreference() {
    this.preferenceService.openPreference();
  }

  searchOnFocus(state: boolean) {
    this.isSearchOpened = state;
  }

  private get isNoneNavigationRoute() {
    return (
      this.router.url.includes('/authenticate') ||
      this.router.url.includes('/payment')
    );
  }

  private get isCryptoRoute() {
    return this.router.url.includes('/crypto');
  }

  private getCategoryList() {
    this.panelService
      .getCategoryList({ is_collection: false })
      .subscribe((categories) => {
        this.categories = categories.map((c) => ({
          ...c,
          id: c.category_detail.id,
          url: `/category/${c.category_detail.id}/${this.getCategoryUrlFriendlyName(c.name)}`,
        }));
      });
  }

  private getCategoryUrlFriendlyName(categoryName: string) {
    return categoryName.replace(/[^a-zA-Z0-9]+/g, '-');
  }

  closeDialog() {
    if (this.dialogRef && this.dialogRef.getState() !== MatDialogState.CLOSED) {
      this.dialogRef.close();
      this.categoryDialogIsOpen = false;
    }
  }

  private checkRoutes() {
    this.stateService.isSearching.subscribe((isSearch) => {
      if (!this.isDesktop) {
        this.isSearchOpened = isSearch;
      }
    });
  }

  private setLoginStatus() {
    this.isLoggedIn = this.isBrowser && this.localStorageService.isLoggedIn;
    this.stateService.setLoginStatus(this.isLoggedIn);
  }
}
