export const ForbiddenRoutes = [
  '',
  'panel',
  'product',
  'edit-profile',
  'charge-wallet',
  'profile',
  'payment',
  'category',
  'access-denied',
  'authenticate',
  'contact-us',
  'pages',
  'home',
  'checkout',
  'search',
  'order-history',
  'edit',
  'wishlist',
  'wallet',
  'crypto',
];
