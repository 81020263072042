import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedProductComponent } from './components/shared-product/shared-product.component';
import { LAZYLOAD_IMAGE_HOOKS, LazyLoadImageModule } from 'ng-lazyload-image';
import { MatButtonModule } from '@angular/material/button';
import { ProductPricePipe } from './pipes/convert-price.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { LazyLoadService } from './services/lazy-load.service';
import { CookieSettingsComponent } from './components/cookie-settings/cookie-settings.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FaqComponent } from './components/faq/faq.component';
import { SharedProductListItemComponent } from './components/shared-product-list-item/shared-product-list-item.component';
import { NoResultPageComponent } from './components/no-result-page/no-result-page.component';
import { FilterChipComponent } from './components/filter-chip/filter-chip.component';
import { MatChipsModule } from '@angular/material/chips';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { SmsComponent } from '../authenticate/components/sms/sms.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedButtonComponent } from './components/shared-button/shared-button.component';
import { MatMenuModule } from '@angular/material/menu';
import { LoginComponent } from '../authenticate/components/login/login.component';
import { FeaturedViewComponent } from './components/featured-view/featured-view.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { PreferenceComponent } from './components/preference/preference.component';
import { SearchFieldComponent } from './components/search-field/search-field.component';
import { SearchSuggestionViewComponent } from './components/search-suggestion-view/search-suggestion-view.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ClickOutsideElementDirective } from './directive/click-outside-element.directive';
import { SearchBriefResultComponent } from './components/search-brief-result/search-brief-result.component';
import { AnimatedBarComponent } from './components/animated-bar/animated-bar.component';

@NgModule({
  declarations: [
    SharedProductComponent,
    SharedProductListItemComponent,
    ProductPricePipe,
    CookieSettingsComponent,
    FaqComponent,
    NoResultPageComponent,
    PhoneNumberPipe,
    FilterChipComponent,
    SmsComponent,
    SharedButtonComponent,
    LoginComponent,
    FeaturedViewComponent,
    PreferenceComponent,
    SearchFieldComponent,
    SearchSuggestionViewComponent,
    ClickOutsideElementDirective,
    SearchBriefResultComponent,
    AnimatedBarComponent
  ],
  imports: [
    CommonModule,
    MatChipsModule,
    LazyLoadImageModule,
    MatButtonModule,
    TranslateModule,
    RouterModule,
    SlickCarouselModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatInputModule,
    MatDialogModule,
    MatIconModule,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule
  ],
  providers: [{ provide: LAZYLOAD_IMAGE_HOOKS, useClass: LazyLoadService }],
  exports: [
    SharedProductComponent,
    FaqComponent,
    ProductPricePipe,
    SharedProductListItemComponent,
    NoResultPageComponent,
    FilterChipComponent,
    PhoneNumberPipe,
    SmsComponent,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    FeaturedViewComponent,
    SharedButtonComponent,
    LoginComponent,
    SearchFieldComponent,
    SearchSuggestionViewComponent,
    SearchBriefResultComponent,
    AnimatedBarComponent
  ],
})
export class SharedModule {}
