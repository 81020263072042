export enum ApiUrls {
  storeKey = 'customers/store-detail-by-url',
  exchangeDetail = 'products/exchange-rates',
  storeDetail = 'customers/store-detail',
  home = 'home/pages/homepage-by-country',
  homes = 'home/pages',
  loginUrl = 'customers/token',
  signUp = 'customers/register',
  appleLogin = 'customers/apple-login',
  appleConnect = 'customers/apple-connect',
  smsLogin = 'customers/otp/sms-login',
  smsVerification = 'customers/otp/sms-verify',
  googleLogin = 'customers/google-login',
  profile = 'customer-accounts/profile',
  products = 'products/catalogs',
  FAQ = 'products/faqs',
  gateway = 'finance/gateways',
  favorite = 'products/catalogs/favorites',
  orders = 'purchase/orders',
  payments = 'finance/payments',
  category = 'products/store-categories',
  wallet = 'customer-accounts/balance',
  paypal = 'finance/wallets',
  storeCountries = 'locations/get-store-countries',
  countryCode = 'customer-accounts/country-code',
  phoneUpdateSendVerificationCode = 'customers/phone-update-send-verification-code',
  phoneUpdateVerifyCode = 'customers/phone-update-verify-code',
  billingAddress = 'customer-accounts/billing-address',
  walletTransactions = 'customer-accounts/wallet-transactions',
  walletCheckTransactions = 'customer-accounts/wallet-check-transaction',
  storeGroups = 'customers/store-groups',
  searchProductByName = 'products/search-product-by-name',
  trendingQueries = 'products/trends',
  logProductSearch = 'products/store-product-search-log',
  cryptoGatewayInternal = 'finance/payment-detail-status',
}

export enum userAccountUrls {
  firebaseLogin = 'users/user-account-firebase-login',
  firebasePhoneUpdate = 'users/user-account-firebase-update',
  firebaseConnect = 'users/user-account-firebase-connect',
  refreshToken = 'users/user-accounts/refresh-token',
  checkPhoneExists = 'users/check-phone-exist',
  binanceLogin = 'users/user-account-binance-login',
}
