import { isPlatformBrowser } from '@angular/common';
import {
  ErrorHandler,
  Inject,
  Injectable,
  PLATFORM_ID,
  isDevMode,
} from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from 'src/environments/environment';

@Injectable()
export class ErrorLogger implements ErrorHandler {
  constructor(@Inject(PLATFORM_ID) private readonly platformId: any) {
    if (isPlatformBrowser(this.platformId)) {
      Sentry.init({
        dsn: 'https://67ad7ad6ea234e7f914fa40e673485eb@o442018.ingest.sentry.io/5878552',
        environment: environment.SENTRY_ENVIRONMENT,
        integrations: [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.routingInstrumentation,
          }),
        ],
        ignoreErrors: [/^Not found.$/],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
      });
    }
  }

  handleError(error: any): void {
    if (!error || !isPlatformBrowser(this.platformId)) return;

    const errException = this.extractError(error);

    if (errException) {
      if(isDevMode()) {
        console.error(errException);
        return;
      };
      Sentry.captureException(errException);
    }
  }

  private extractError(error: any): Error | string | undefined {
    if ('detail' in error && error.detail) {
      return error.detail;
    }

    if ('error' in error && error.error) {
      if ('detail' in error.error && error.error.detail) {
        return error.error.detail;
      }
      return error.error;
    }

    if ('message' in error && error.message) {
      return error.message;
    }

    if (error instanceof Error) {
      return error;
    }

    return undefined;
  }
}
