export const environment = {
  production: true,
  baseUrl: 'https://broker.rhinobit.net/api/v1',
  defaultLang: 'en',
  SENTRY_ENVIRONMENT: 'production',
  // storeId: 'e54c9c8f-5e83-4940-8c0c-b2134468bbbb',
  clientId:
    '335951033022-ui3350pk8nrg6c37djk6o6niqukquimb.apps.googleusercontent.com',
  appleClientId: '42K6N3L295.com.kingiftcard',
  userAccountBaseUrl: 'https://apiaccount.rhinobit.net/api/v1',
};
